<template>
  <div>
    <span id="qqLoginBtn" @click="doQQLogin"></span>
  </div>
</template>

<script>
export default {
  name: 'qqLogin',
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    doQQLogin() {
      if (!this.$store.state.loginChangBox) {
        this.$message('请先勾选同意协议');
        return;
      }
      location.href = 'https://api.baiwanqilin.com/qilin-user/v1/qqLogin';
    },
    setqqLogin(index) {
      if (this.$store.state.loginChangBox) {
        QC.Login({ btnId: 'qqLoginBtn' });
      } else {
        if (index == 1) this.$message('请先勾选同意协议');
        QC.Login.signOut();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog,
.iframe {
  width: 100%;
  height: 100vh;
  margin-top: 0 !important;
}
#qqLoginBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
