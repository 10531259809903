<template>
  <div class="login">
    <div class="login_main">
      <div class="fsb login_main_top">
        <div class="left">
          <span :class="{ active: topActive == 0 }" @click="topLogin(0)">手机号登录</span>
          <span :class="{ active: topActive == 1 }" @click="topLogin(1)">密码登录</span>
        </div>
        <!-- <i class="el-icon-close"></i> -->
      </div>
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <div v-if="topActive == 0">
          <el-form-item
            class="mobel-form"
            prop="identifier"
            :rules="[
              { required: true, message: '手机号不能为空', trigger: 'blur' },
              {
                type: 'number',
                message: '手机号必须为数字值',
                trigger: 'blur'
              },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: '输入手机号有误',
                trigger: 'blur'
              }
            ]"
          >
            <el-input v-model.number="ruleForm.identifier" placeholder="手机号" autocomplete="off" maxlength="11" :class="ruleForm.identifier"></el-input>
            <div class="flex mobel">
              <span>+86</span>
              <span class="iconfont icon-xiajiantou"></span>
            </div>
          </el-form-item>
          <el-form-item
            prop="code"
            class="verificationCode-form"
            :rules="[
              { required: true, message: '验证码不能为空', trigger: 'blur' },
              {
                type: 'number',
                message: '验证码必须为数字值',
                trigger: 'blur'
              },
              {
                pattern: /^\d{6}$/,
                message: '验证码长度必须是6位'
              }
            ]"
          >
            <el-input v-model.number="ruleForm.code" placeholder="验证码" autocomplete="off" maxlength="6" :class="ruleForm.code"></el-input>
            <div class="flex verificationCode">
              <span v-if="secondCode" :class="{ getCode: getMobel }" @click="fnSecondCode">获取验证码</span>
              <span v-else :class="{ second: !secondTime }">{{ secondTime }}秒</span>
            </div>
          </el-form-item>
          <div class="pasTip">
            <span>未注册手机账号验证后将自动注册</span>
          </div>
        </div>
        <div v-if="topActive == 1">
          <el-form-item prop="identifier" :rules="[{ required: true, message: '输入账号不能为空', trigger: 'blur' }]">
            <el-input v-model="ruleForm.identifier" placeholder="输入账号" autocomplete="off" :class="ruleForm.identifier"></el-input>
          </el-form-item>

          <el-form-item prop="credential" :rules="[{ required: true, message: '密码不能为空', trigger: 'blur' }]">
            <el-input v-model="ruleForm.credential" :type="passwordType" placeholder="输入密码" autocomplete="off" :class="ruleForm.credential"></el-input>
            <div class="fac password">
              <span class="iconfont" @click="passwordType == 'password' ? (passwordType = 'text') : (passwordType = 'password')" :class="passwordType == 'password' ? 'icon-yanjing-guan' : 'icon-yanjing_huaban1'"></span>
            </div>
          </el-form-item>

          <div class="fsb pasTip" style="display:none;">
            <el-checkbox v-model="checkedPsd" checked>记住密码</el-checkbox>
            <span>忘记密码？</span>
          </div>
        </div>
        <el-form-item>
          <el-button class="submitForm" :disabled="topActive ? !getNamePas : !getSecondCode" @click="submitForm">登录</el-button>
        </el-form-item>
      </el-form>

      <div class="login_main_botton">
        <el-divider>第三方快捷登录</el-divider>

        <div class="fcc icon" style="width:100%;margin-top: 10px;">
          <div style="width:250px;float:left;text-align: right;">
            <el-popover placement="top-start" trigger="manual" v-model="visible">
            <iframe frameborder="0" height="400" src="https://open.weixin.qq.com/connect/qrconnect?appid=wxc87e668b846acd27&redirect_uri=https%3A%2F%2Fapi.baiwanqilin.com%2Fqilin-user%2Fv1%2FcallBack&response_type=code&scope=snsapi_login&state=15F9CB5780E5C212B75E141E9EED0B64#wechat_redirect"></iframe>
            <div slot="reference" class="icon_img" @click="clickPopover">
              <img src="../assets/img/weixin.png" />
              <span>微信登录</span>
            </div>
          </el-popover>
          </div>
          <div class="icon_img qq_logo" style="float:right;width: 150px;text-align: left;">
            <img src="@/assets/img/creatorPlatform/qq_logo.png" />
            <QQLogin></QQLogin>
            <span class="QQSpan">QQ登录</span>
          </div>
        </div>
        <div class="agreement" style="width:100%;margin-top: 160px;">
          <el-checkbox v-model="checked" @change="changBox" checked></el-checkbox>
          <span @click="fnRouter(1)">我已阅读并同意以下《用户协议》和</span>
          <span @click="fnRouter">《隐私政策》</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true" class="policyAndagreement" top="5vh">
      <agreement v-if="dialogVisibleTitle == 1" />
      <policy v-else />
    </el-dialog>
  </div>
</template>
<script>
import QQLogin from '@/components/public/QQlogin.vue';
import { login, sendSms, getCode } from '@/utils/serveApi';
import { localStorages } from '@/utils/public';
import policy from '@/components/public/policy.vue';
import agreement from '@/components/public/agreement.vue';
import secret from '@/utils/secret';
export default {
  name: 'login',
  data() {
    return {
      topActive: 1,
      secondCode: true,
      visible: false,
      secondTime: 120,
      passwordType: 'password',
      checkedPsd: false,
      checked: false,
      timer: null,
      ruleForm: {
        identifier: '',
        credential: '',
        code: ''
      },
      datas: {
        id: 0, //0不填
        userId: '', //0不填
        identifier: '', //手机号登录时此字段必填  ,当第三方登录(微信,qq,苹果)时:此字段不填
        credential: '', //当手机号登录时,此字段为密码,  当第三方登录时,此字段为从第三方获取的token
        identitytype: '1', //1.手机号 密码登录,6.手机号 验证码登录,2.微信登录,3.qq登录,5.苹果登录,4.本机号码一键登录  这是前台传来的类型
        ifverified: '', //不填
        code: '', //验证码,选填  手机号验证码登录时
        nickName: '', //第三方名称,选填,第三方登录时 从第三方获取的名称
        iconUrl: '', //第三方头像,选填,第三方登录时 从第三方获取的头像
        gender: '', //性别,不填
        phoneSystemType: '', //是ios系统填ios,是安卓系统,填android
        refersId: 0 //选填,这个是你打开别人分享给你的链接,里面带的
      },
      dialogVisible: false,
      dialogVisibleTitle: ''
    };
  },
  created() {
    const userNameAndPsd = localStorages('userNameAndPsd');
    this.checked = this.$store.state.loginChangBox;
    if (userNameAndPsd && userNameAndPsd.identifier) {
      this.ruleForm = userNameAndPsd;
      this.checkedPsd = true;
    }
  },
  computed: {
    getMobel() {
      if (this.ruleForm.identifier == '') {
        return false;
      }
      return true;
    },
    getNamePas() {
      if (this.topActive && this.ruleForm.identifier && this.ruleForm.credential) {
        return true;
      }
      return false;
    },
    getSecondCode() {
      if (!this.topActive && this.ruleForm.identifier && this.ruleForm.code) {
        return true;
      }
      return false;
    }
  },
  methods: {
    submitForm() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          if (this.clickBtn()) return;
          let datas = {};
          if (this.ruleForm.code) {
            datas.identitytype = '6';
            datas.code = this.ruleForm.code.toString();
            datas.identifier = this.ruleForm.identifier.toString();
          } else {
            datas = { ...this.datas, ...this.ruleForm };
          }
          
          let encryptData = secret.encrypt(JSON.stringify(datas));
          
          this.clearTime();
          //alert(1);
          login(encryptData).then(res => {
            console.log(JSON.stringify(res))
            if (res?.stateDesc && !res.success) this.$message.error(res.stateDesc);
            if (!res?.success) return;
            const { userBackgroundWall, userId, userImg, userName, userPhone, sex, age,memberStatus } = res.data.data;
            const userData = {
              token: res.data.token,
              userBackgroundWall,
              userId,
              userImg,
              userName,
              userPhone,
              memberStatus,  //1会员，2已到期
              sex,
              age
            };
            //alert("登录成功");
            localStorages('userData', userData);
            //this.$store.commit('userData', userData);
            //this.$router.push('/video');
            //alert(this.checkedPsd);
            this.checkedPsd ? localStorages('userNameAndPsd', this.ruleForm) : localStorages.removeItem('userNameAndPsd');
            //window.location.href="/";
            //alert(1);
            window.location.reload();
            //window.location.href="/";
          });
          //alert(22);
        } else {
          
          return false;
        }
      });
    },
    topLogin(index) {
      const ruleForm = this.ruleForm;
      ruleForm.identifier = '';
      ruleForm.credential = '';
      ruleForm.code = '';
      this.topActive = index;
    },
    clearTime() {
      clearInterval(this.timer);
      this.secondCode = true;
      this.secondTime = 120;
    },
    fnSecondCode() {
      if (this.getMobel) {
        this.ruleForm.verificationCode = '';
        this.secondCode = false;
        let secondTime = 120;
        this.timer = setInterval(() => {
          if (secondTime == 0) {
            this.clearTime();
          } else {
            secondTime--;
            this.secondTime = secondTime;
          }
        }, 1000);
        sendSms(this.ruleForm.identifier);
      } else {
        this.$message('手机号不能为空');
      }
    },
    fnRouter(tltle) {
      this.dialogVisible = true;
      this.dialogVisibleTitle = tltle;
    },
    changBox(val) {
      this.$store.commit('loginChangBox', val);
    },
    clickBtn() {
      if (!this.checked) {
        this.$message('请先勾选同意协议');
        return true;
      }
      return false;
    },
    clickPopover() {
      if (this.clickBtn()) return;
      this.visible = !this.visible;
    }
  },
  components: { QQLogin, policy, agreement }
};
</script>
<style lang="scss" scoped>
$defaultColor: #c1c1c1;
.login {
  // background-color: rgba(34, 34, 34, 0.4);
  // height: 100vh;
  overflow: hidden;
  font-size: 16px;
  &_main_top {
    margin-bottom: 30px;
    .left,
    .el-icon-close {
      color: #999999;
    }
    .el-icon-close {
      font-size: 30px;
    }
    .left {
      span {
        cursor: pointer;
        padding-right: 20px;
      }
      .active {
        font-size: 20px;
        color: #000000;
        font-weight: bold;
      }
    }
  }
  &_main {
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 10px;
    padding: 32px 40px;
    font-size: 16px;
    .el-form-item {
      color: $defaultColor;
    }
    ::v-deep .mobel-form {
      .mobel {
        border-right: 1px solid #c1c1c1;
        position: absolute;
        top: 12px;
        left: 14px;
        line-height: 20px;
        .iconfont {
          padding: 0 32px 0 12px;
        }
      }
      .el-input__inner {
        padding-left: 150px;
      }
    }
    .verificationCode-form {
      .verificationCode {
        position: absolute;
        top: 6px;
        right: 14px;
        span {
          cursor: no-drop;
        }
        .getCode {
          color: #ffb600;
          cursor: pointer;
        }
        .second {
          cursor: pointer;
        }
      }
    }
    .password {
      position: absolute;
      top: 0;
      //right: $r;
      height: 100%;
      cursor: pointer;
      .el-input__inner {
        padding-right: 60px;
      }
    }
    .el-input__inner {
      border-color: $defaultColor;
      height: 52px;
      box-sizing: border-box;
      font-size: 16px;
      color: #000000;
    }
    .el-input__inner:focus {
      border-color: #000000;
    }
    .el-form-item.is-error .el-input__inner:focus {
      border-color: #fb2612;
    }
    .pasTip {
      span {
        color: $defaultColor;
      }
    }
    .submitForm {
      width: 100%;
      display: block;
      height: 52px;
      min-height: 30px;
      line-height: 52px;
      text-align: center;
      font-size: 20px;
      color: #ffffff;
      background-color: #ffb600;
      opacity: 1;
      border-radius: 10px;
      margin-top: 24px;
      padding: 0;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:hover {
      color: #ffffff;
      background-color: #ffb600 !important;
      opacity: 0.4;
    }
  }
  &_main_botton {
    .el-divider--horizontal {
      margin: 40px 0 0;
    }
    .el-divider__text {
      font-size: 16px;
      color: $defaultColor;
    }
    .icon {
      span {
        display: block;
        font-size: 14px;
        color: $defaultColor;
      }
      .icon_img {
        text-align: center;
        margin: 33px;
        cursor: pointer;
        & > img {
          width: 56px;
        }
      }
      .qq_logo {
        position: relative;
        & > img {
          width: 51px;
        }
      }
    }
    .QQSpan {
      margin-top: -6px;
    }
    .agreement {
      font-size: 16px;
      color: #999999;
      span {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
}
.policyAndagreement {
  ::v-deep .el-dialog {
    width: 80%;
    height: inherit;
    margin-bottom: 5vh;
    .el-dialog__close {
      font-size: 40px;
    }
  }
}
</style>
