<template>
    <div class="MainNav-container">
        <div class="logo">
            <img src="../../assets/logo.png" alt="" />
        </div>
        <ul>
            <li
                v-for="(item, index) in navArr"
                :key="item.index"
            >
                <a v-if="item.type=='link'" :href="item.url" target="_blank" >
                    <img
                        :src="item.filePath"
                        alt=""
                        class="icon"
                        :class="{ active: item.url == currentURL }"
                    />
                    <span :class="{ active: item.url == currentURL }">{{item.title}}</span>
                </a>
                <router-link v-else :to="item.url">
                    <img
                        :src="item.filePath"
                        alt=""
                        class="icon"
                        :class="{ active: item.url == currentURL }"
                    />
                    <span :class="{ active: item.url == currentURL }">{{item.title}}</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentURL:null,
            navArr: [
                {
                    title: "首页",
                    url: "/",
                    filePath: require("../../assets/home3.png"),
                },
                {
                    title: "推荐",
                    url: "/recommend",
                    filePath: require("../../assets/recommend3.png"),
                },
                {
                    title: "关注",
                    url: "/follow",
                    filePath: require("../../assets/follow3.png"),
                },
                {
                    title: "我的",
                    type:'link',
                    url: "http://www.baiwanqilin.com/#/uploadVideo",
                    filePath: require("../../assets/follow3.png"),
                },
                /*
                {
                    title: "热点",
                    url: "/hot",
                    filePath: require("../../assets/hot3.png"),
                },*/
            ],
        };
    },
    watch: {
        // 监听路由：用于控制导航栏的选中状态
        $route: {
            handler(newVal) {
                this.currentURL=newVal.path
            },
            immediate: true // 进入页面执行一次
        }
    }
};
</script>

<style lang="less" scoped>
.logo {
    margin-left: 5px;
    width: 172px;
    height: 60px;
    text-align: center;
    img {
        width: 100%;
        height: auto;
        margin-top: 10px;
    }
}
ul li {
    display: block;
    width: 84px;
    height: 36px;
    margin: 12px 48px 0 40px;
    list-style: none;
    cursor: pointer;
    a {
        display: inline-block;
        font-size: 16px;
        line-height: 36px;
        color: rgba(255, 255, 255, 0.5);
    }
    .icon {
        width: 36px;
        height: 36px;
        vertical-align: bottom;
        opacity: 0.5;
    }
}
li:hover a {
    color: #fff;
}
li:hover .icon {
    opacity: 1;
    opacity: 1;
}
.active {
    color: #fff;
    opacity: 1 !important;
}
</style>