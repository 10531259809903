<template>
  <div
    class="swiper-video-container"
    :style="`width: ${innerWidth}; height: ${innerHeight}`"
  >
    <!-- 轮播区域 -->
    <swiper
      class="my-swipe"
      ref="playerSwiper"
      :options="swiperOptions"
      v-if="videoList.length"
      @slideChange="_slideChange"
      @reachEnd="reachEnd"
      navigation
    >
      <swiper-slide v-for="(item, index) in videoList" :key="item.videoId">
        <!-- 右侧信息区域 -->
        <div class="right_menus">
          <!-- 作者 
                    <div class="menuClick">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="进入作者主页"
                            placement="right-start"
                        >
                            <img class="avatar" :src="item.avatar" alt="" />
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="关注"
                            placement="right-start"
                        >
                            <div class="follow">
                                <i class="van-icon van-icon-plus"></i>
                            </div>
                        </el-tooltip>
                    </div>
                    
                    <div class="click-info">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="点赞"
                            placement="right-start"
                        >
                            <i class="van-icon van-icon-like"></i>
                        </el-tooltip>
                        <div class="text">{{ item.like_num }}</div>
                    </div>
                    
                    <div class="click-info">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="评论"
                            placement="right-start"
                        >
                            <i class="van-icon van-icon-chat"></i>
                        </el-tooltip>
                        <div class="text">{{ item.comment_num }}</div>
                    </div>
                    
                    <div class="click-info">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="收藏"
                            placement="right-start"
                        >
                            <i class="van-icon van-icon-star"></i>
                        </el-tooltip>
                        <div class="text">{{ item.collect_num }}</div>
                    </div>
                    
                    <div class="click-info">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="分享"
                            placement="right-start"
                        >
                            <i class="van-icon van-icon-share"></i>
                        </el-tooltip>
                        
                    </div>
                    -->
          <!-- 切换按钮 
                    <div class="toggle-swiper">
                        <div class="swiper-button-prev" slot="button-prev">
                            <i class="van-icon van-icon-arrow-up"></i>
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <i class="van-icon van-icon-arrow-down"></i>
                        </div>
                    </div>-->
        </div>
        <!-- 底部文本区域 -->
        <div class="text-container">
          <div class="nickname">
            @{{ item.nickname ? item.nickname : item.username }}
            <span style="font-size: 12px">{{ item.userTitle }}</span>
            <div style="font-size: 12px; margin-top: 5px; margin-bottom: 5px">
              {{ item.labelOne }}
            </div>
          </div>
          <div class="msg">
            {{ item.videoTitle
            }}<span
              v-show="showfull"
              style="float: right; color: red"
              @click="gofullvideo(item)"
              >>>免费试听</span
            >
          </div>
        </div>
        <!-- 播放器 -->
        <div
          class="video-content"
          :id="`player${index}`"
          :src="showfull ? item.videoEditingPlayUrl : item.videoPlayUrl"
          preload="auto"
          controls
          loop
          width="100%"
          type="video/mp4"
          playsinline="true"
          x5-playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="allow"
          oncontextmenu="return false;"
          controlslist="nodownload noremoteplayback"
          :disablePictureInPicture="true"
        ></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  props: ["videoList", "innerWidth", "innerHeight"],
  data() {
    return {
      swiperOptions: {
        direction: "vertical",
        mousewheel: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showfull: true,
    };
  },
  computed: {
    swiper() {
      return this.$refs.playerSwiper.$swiper;
    },
  },

  created() {
    var url = window.location.href;
    //获取当前url
    var dz_url = url.split("?"); //获取#/之前的字符串
    var vid = 0;
    var vname = "";
    if (dz_url.length > 1) {
      vname = dz_url[1].split("=")[0];
      vid = dz_url[1].split("=")[1];
    }
    if (vid > 0 && vname == "id") {
      this.showfull = false;
    }
    const videoList = this.videoList;
    console.log("videoList.length", videoList.length);
    setTimeout(() => {
      this.initVideoList()
    }, 0);
  },
  watch: {
    videoList(to,from){
      this.initVideoList()
    },
    $route(to, from) {
      //alert(this.$route.query.skey)
      //this.getData(this.$route.query.id)
      var id = this.$route.query.id;
      //alert("a:"+id);
      if (id > 0) {
        //alert(id)
        this.showfull = false;
      } else {
        this.showfull = true;
      }

      //this.videoList(k);
    },
  },
  methods: {
    initVideoList() {
      setTimeout(() => {
        this.videoList.forEach((item, index) => {
        if (!item.player) {
          const selecter=`player${index}`
          item.player = new Aliplayer(
            {
              // controlBarVisibility:'always',
              autoplay: index==0,
              id: selecter,
              source: this.showfull
                ? item.videoEditingPlayUrl
                : item.videoPlayUrl, //播放地址，可以是第三方点播地址，或阿里云点播服务中的播放地址。
            },
            function (player) {
              console.log("The player is created.");
            }
          );
          setTimeout(() => {
            var element = document.getElementById(selecter);
            if(element){
              element.style.height = '';
            }
          }, 0);
        }
      });
      }, 0);
    },
    gofullvideo(item) {
      //console.log("初始")
      //console.log(item);

      if (
        localStorage.getItem("userData") == null ||
        localStorage.getItem("userData") == ""
      ) {
        this.$alert("<strong><i>请登录</i> 系统</strong>", "提示", {
          dangerouslyUseHTMLString: true,
        });
      } else {
        //alert(vid);
        this.$https.videos.fullvoide(item.videoId).then(({ data }) => {
          //alert(111)
          //console.log("新：")
          //console.log(data);
          var cansee = data.canSee.type;

          //alert(cansee);
          if (cansee == 0) {
            //alert(0);
            this.$alert(
              "<strong><i>对不起，非会员没有查看完整视频权限</i> 请开通会员</strong>",
              "提示",
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            this.videoList.forEach((item, s_index) => {
              //alert(s_index)
              item.videoPlayUrl = data.video.videoPlayUrl;
              document.getElementById(`player${s_index}`).play();
              item.play = true;
            });
            this.$router.push({
              name: "Player",
              query: { id: item.videoId },
            });

            localStorage.setItem("rowData", JSON.stringify(item));
          }
        });
      }
    },
    reachEnd() {
      this.$emit("reachEnd");
    },
    // swiper 切换
    _slideChange() {
      const index = this.swiper.activeIndex;

      this.$emit("slideChange", index);
      this.videoList.forEach((item, s_index) => {
        if (item.player) {
          if (s_index == index) {
            item.player.play();
            item.play = true;
          } else {
            item.player.pause();
            item.play = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-video-container {
  position: fixed;
  // width: 88%;
  // height: calc(100% - 130px);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  .my-swipe {
    position: relative;
    width: 100%;
    // 解决滑动轮播时有白色线条的Bug
    height: 102%;
    .swiper-slide {
      position: relative;
      height: 100%;
      .video-content {
        width: 100%;
        height: 100%;
        background-color: #000;
        // object-fit: cover;
      }
      .right_menus {
        position: absolute;
        width: 50px;
        bottom: 80px;
        right: 20px;
        z-index: 1;
        text-align: center;
        .menuClick {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          .avatar {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .follow {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            font-size: 12px;
            color: #fff;
            background-color: #fe2c55;
            border-radius: 50%;
            line-height: 20px;
          }
        }
        .click-info {
          position: relative;
          margin-bottom: 20px;
          i {
            font-size: 24px;
            color: #fff;
          }
          .text {
            color: #fff;
            font-size: 16px;
            margin-top: 5px;
          }
        }
      }
      .text-container {
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        width: 30%;
        left: 0;
        bottom: 80px;
        padding: 10px 30px 10px;
        box-sizing: border-box;
        z-index: 1;
        .nickname {
          width: 100%;
          font-size: 20px;
          color: #fff;
          text-align: left;
        }
        .msg {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          font-size: 16px;
          text-align: left;
          color: #fff;
        }
      }
    }
  }
  // 切换按钮
  .toggle-swiper {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 80px;
    background-color: #323442;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 40px;
    position: static;
    color: #fff;
    margin-top: 0;
    &::after {
      content: "";
    }
    &:hover {
      color: #fe2c55;
    }
    i {
      font-weight: bold;
    }
  }
}

.swiper-video-container .my-swipe .swiper-slide .video-content{
  height: 100vh;
}
.contentBox .swiper-video-container .my-swipe .swiper-slide .video-content{
  height: 100%;
}
</style>
