<template>
  <el-card style="height: 100%">
    <div slot="header" class="clearfix">
      <span v-if="userData">欢迎回来，{{ userData.userName }}</span>
      <el-button v-else type="text" style="padding: 0" @click="goLogin"
        >登录/注册</el-button
      >
    </div>
    <div
      style="text-align: center"
      v-if="!userData || userData.memberStatus != 1"
    >
      <p>会员年卡，一年随心畅学</p>
      <div>
        <img
          class="icon"
          src="@/assets/wxm.jpg"
          alt=""
          style="width: 175px; height: 175px"
        />
      </div>
      <el-button type="primary" @click="dialogVisible=true">立即开通</el-button>
    </div>
    <el-dialog
    v-if="dialogVisible"
      title="购买年卡会员"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
    <Pay />
    </el-dialog>
     
  </el-card>
</template>
<script>
import Pay from "./Pay";

export default {
  components: { Pay },
  name: "User",
  data() {
    return {
      showAgreement: false,
      userData: null,
      siteList: [],
      loading: true,
      dialogVisible: false,
    };
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
   
    goLogin() {
      this.$store.commit("isLogin", true);
    },
  },
};
</script>
