import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件
import Home from '../views/Home.vue'
import Recommend from "../views/index/Recommend.vue"
import Discover from "../views/discover/Discover.vue"
import Dashboard from "../views/dashboard/Dashboard.vue"
import Hot from "../views/hot/Hot.vue"
import Follow from "../views/follow/Follow.vue"
import PlayerVideo from "../views/player/player.vue"
import AboutUs from "../views/AboutUs.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      { path: "", component: Dashboard }, // 首页
      { path: "Discover", component: Discover }, // 搜索
      { path: "Dashboard", component: Dashboard }, // 首页
      // path 为空，表示默认展示Recommend组件
      { path: "Recommend", component: Recommend }, // 推荐
      { path: "Follow", component: Follow }, // 关注
      { path: "Hot", component: Hot }, // 热点
    ]
  },
  { 
    path: "/Home5", 
    name:"Home5",
    component: AboutUs 
  }, 
  {
    path: '/player',
    name: "Player",
    component: PlayerVideo
  }
]

const router = new VueRouter({
  routes
})

export default router
