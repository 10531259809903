<template>
    <el-card style="height: 100%">
          <swiper navigation>
            <swiper-slide v-for="item in siteList" :key="item.id">
              <div>
                <el-image
                  style="max-width: 100%; height: 300px"
                  :src="item.brand"
                  :alt="item.title"
                  fit="cover"
                ></el-image>
              </div>
            </swiper-slide>
          </swiper>
        </el-card>
</template>

<script>
import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.baiwanqilin.com/qilin-tool/v1",
});
export default {
  name: "Slide",
  data() {
    return {
      siteList: []
    };
  },
  async created() {
    const {
      data: { data },
    } = await instance.get("/site/getSite");
    this.siteList = [data];
  }
};
</script>
