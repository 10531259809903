import axios from '@/http/https.js'

const vtps = {
    // 课程类别
    tpList(data) {
        //console.log("读取类别接口：");
        return axios.get('/category/list',{
            //params:{}, //如果没有传参，直接注释掉此行代码
            Headers:{
                'x-access-token':sessionStorage.getItem("utoken")
            }
        }, data) 
    },
    // 推荐视频
    commandList(data) {
        //console.log("读取推荐视频接口：");
        return axios.post('/cecommend/recommendVideo',{
            params:{
                categoryId:1,
                pageNum:1,
                pageSize:10
            }, //如果没有传参，直接注释掉此行代码
            Headers:{
                'x-access-token':sessionStorage.getItem("utoken")
            }
        }, data) 
    },
}

export default vtps