<template>
    <div class="short-video-player">
        <swiper-video 
            @reachEnd="reachEnd"
            @slideChange="slideChange"
            :videoList="videoList"
            :innerWidth="w"
            :innerHeight="h">
        </swiper-video>
    </div>
</template>

<script>
import SwiperVideo from "@/components/swiper/index.vue"

export default {
    components: {
        SwiperVideo
    },
    data() {
        return {
            videoList: [],
            w: "88%",
            h: "calc(100% - 130px)",
            pageNum:1
        };
    },
    methods: {
        reachEnd(){
            this.pageNum++
            this._videoList();
        },
        slideChange(index){
            console.log('slideChange',index)
        },
        _videoList() {
            /*
            this.$http.video.hotList().then(({ code, data }) => {
                if (code === 200) {
                    this.videoList = this.videoList.concat(data.list);
                }
            })*/
            
            this.$https.videos.commandList({},this.pageNum).then(({ code, data }) => {
                //alert(data[0]);
                //console.log(data[0].videoPlayUrl)
                //if (code === 200) {
                    this.videoList = this.videoList.concat(data);
                //}
            })
        },
    },
    created() {
        this._videoList();
    },
};
</script>

<style lang="less" scoped>

</style>