import axios from '@/http/https.js';
var qs = require('qs');
const videos = {
    tpList(data) {
        //console.log("读取类别接口：");
        return axios.get('/category/list',{
            //params:{}, //如果没有传参，直接注释掉此行代码
            Headers:{
                'x-access-token':sessionStorage.getItem("utoken")
            }
        }, data) 
    },
    // 推荐视频
    commandList(data,pageNum) {
        //console.log("读取推荐视频接口：");
        var url = window.location.href ;             //获取当前url
            var dz_url = url.split('?');                //获取#/之前的字符串
            var tpid="1";
            if(dz_url.length>1)
            {
                tpid=dz_url[1].split('=')[1];
            }
            var parm=qs.stringify({
                categoryId:tpid,
                pageNum:pageNum,
                pageSize:10,
            })
            //alert(tpid);
            //var tk=JSON.parse(localStorage.getItem('userData')).token;
        return axios.post('/cecommend/recommendVideo',parm,{
            Headers:{
                'token':sessionStorage.getItem("utoken")
            }
        }, data) 
    },
    // 关注视频s
    followList(data,pageNum) {
       /*
        var parm=qs.stringify({
            pageNum:1,
            pageSize:10
        })*/
       
        const params = new URLSearchParams()
        params.append('pageNum',pageNum)
        params.append('pageSize',10)
        /*
        if(localStorage.getItem('userData')==null)
        {
            this.$alert('<strong><i>对不起，登录系统后才能查看您的关注内容</i> </strong>', '提示', {
                dangerouslyUseHTMLString: true
                }); 
            return;
        }*/
        var tk=JSON.parse(localStorage.getItem('userData')).token;
        
        return axios.post('/cecommend/recommendAttention',params,{
            data:params,
            Headers:{
                'token':tk,
                'Authorization': `Bearer `+tk,
                'content-Type': 'application/x-www-form-urlencoded'
            },
        }, data) 
    },
     
    // 搜索视频
    searchList(k,data) {
        //console.log("读取推荐视频接口：");
        /*
        var url = window.location.href ;             //获取当前url
            var dz_url = url.split('?');                //获取#/之前的字符串
            var selkey="";
            if(dz_url.length>1)
            {
                selkey=dz_url[1].split('=')[1];
            }*/
            //alert(decodeURI(k));
            var parm=qs.stringify({
                //keyword:decodeURI(selkey),
                keyword:decodeURI(k),
            })
            //const params = new URLSearchParams()
            //params.append('keyword',selkey)
            //alert(tpid);
        return axios.post('/search/searchKey',parm,{
        }, data) 
    },
    //查看完整视频
    fullvoide(vid,data) {
        var parm=qs.stringify({
            //keyword:decodeURI(selkey),
            videoId:vid,
        })
        return axios.post('/watchFull/getVideo',parm,{
        }, data) 
    },
}

export default videos