import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'
import https from './http'

import Notify from 'vue-notifyjs'
Vue.use(Notify)

// 引入 Element UI 组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/public.scss';
import "./utils/rem"

// Vant
import { Icon } from 'vant';

// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(ElementUI);

Vue.prototype.$http = http
Vue.prototype.$https = https

Vue.config.productionTip = false

Vue.use(Icon);

Vue.use(VueAwesomeSwiper)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
