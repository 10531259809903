<template>
  <div class="aboutUs">
    <TopTitle title="公司简介"></TopTitle>
    <div class="top fsb">
      <div class="top_text">
        <!-- <div class="top_title">百万麒麟</div> -->
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;百万麒麟是一款以全球各国语言学习、考研、考公、教资、专升本、大学四六级、雅思 托福为主要内容的知识共享类网络平台，主要内容涵盖汉、英、法、日、韩、德、俄、西班 牙等国语言课程，从零基础到日常及商务口语，还涵盖了国内用户考证、考级、出国留学等 实用性知识内容，分享者来自世界名牌大学硕士或者博士。教学经验丰富，干货十足。所有 语种均有多位中国老师和外籍老师（母语国）讲授，创作者来自全球各个国家，所有课程一 元即可学习，随时随地畅学无阻。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;百万麒麟企业愿景： 百万麒麟致力于打造一款强实用性的全科类的知识技能共享平台，打破每个学习者和知 识之间的距离和屏障，同时让分享知识的老师得到社会的价值认可； 百万麒麟还将为在校大学生提供一个开放性的视频化的企业招聘窗口，让大学生提前了 解并认知社会的岗位需求，同时为优质企业提供人才招聘窗口。 百万麒麟还为学生、老师、企业共同搭建了应用性社交、信息服务等交流平台，让大学 生更加容易地融入社会并学以致用，为社会创造价值，树立正确人生观、价值观，快速成长。 未来三年，百万麒麟将打造出一款元宇宙场景的全能化陪伴型高精度人工智能数字人， 该数字人将满足每个人的各项学习需求，用户选择自己要学习的内容并可自定义老师的形象、 声音、学习场景，同时记录下自己的学习经验和生活，为子孙后代留下宝贵的精神财富。</p>
      </div>
      <div class="top_img">
        <img src="../assets/img/aboutUs/top.jpg" />
      </div>
    </div>
    <TopTitle title="关于我们"></TopTitle>
    <div class="aboutUs_video">
      <div class="video fsb">
        <video ref="videoPlay" id="videoPlay" controls :poster="videoArr[index].imgUrl" :src="videoArr[index].videoUrl">
          抱歉，您的浏览器不支持内嵌视频，不过不用担心，你可以
          <a :href="videoArr[index].videoUrl">下载</a>
          并用你喜欢的播放器观看!
        </video>
        <div class="column imgs">
          <img v-for="(item, index) in videoArr.slice(0, 2)" :key="index" :src="item.imgUrl" @click="videoIndex(index)" />
        </div>
      </div>
      <div class="img2 fsb">
        <img v-for="(item, index) in videoArr.slice(2)" :key="index + 2" :src="item.imgUrl" @click="videoIndex(index + 2)" />
      </div>
    </div>
  </div>
</template>

<script>
import TopTitle from '../components/public/TopTitle';
import { handleImage } from '@/utils/public';
export default {
  name: 'aboutUs',
  props: ['id'],
  data() {
    return {
      index: 0,
      videoArr: [
        {
          videoUrl: 'https://img.baiwanqilin.com/upload/web/20210916091436.mp4',
          imgUrl: ''
        },
        {
          videoUrl: 'https://img.baiwanqilin.com/upload/web/20210916091632.mp4',
          imgUrl: ''
        },
        {
          videoUrl: 'https://img.baiwanqilin.com/upload/web/20210916091651.mp4',
          imgUrl: ''
        },
        {
          videoUrl: 'https://img.baiwanqilin.com/upload/web/20210916091651.mp4',
          imgUrl: ''
        },
        {
          videoUrl: 'https://img.baiwanqilin.com/upload/web/20210916091651.mp4',
          imgUrl: ''
        }
      ]
    };
  },
  created() {
    const videoArr = this.videoArr;
    videoArr.forEach(item => handleImage(item.videoUrl, img => (item.imgUrl = img)));
  },
  methods: {
    videoIndex(index) {
      this.index = index;
    }
  },
  components: { TopTitle }
};
</script>
<style scoped lang="scss">
.aboutUs {
  padding: 50px 210px;
  .top {
    margin: 46px auto 80px;
    &_text {
      width: 640px;
      .top_title {
        padding-bottom: 40px;
        font-size: 36px;
        font-weight: bold;
        color: #000000;
      }
      p {
        font-size: 24px;
        color: #222222;
      }
    }
    &_img {
      width: 720px;
      border-radius: 20px;
      & > img {
        width: 100%;
      }
    }
  }
  .aboutUs_video {
    margin: 40px auto 80px;
    .video {
      height: 726px;
      video {
        width: 992px;
        height: 100%;
        object-fit: contain;
        margin-right: 20px;
        border-radius: 20px;
        overflow: hidden;
        background-color: #000;
      }
      .imgs {
        width: 484px;
        height: 100%;
        & > img {
          width: 100%;
          height: 354px;
          border-radius: 20px;
          cursor: pointer;
          object-fit: cover;
        }
      }
    }
    .img2 {
      height: 354px;
      margin-top: 24px;
      & > img {
        width: 484px;
        height: 100%;
        cursor: pointer;
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }
}
</style>
